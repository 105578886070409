import classNames from "classnames";
import { BasicProps } from "components/types/BasicProps";
import { FC } from "react";

export const SearchIcon: FC<BasicProps> = ({ className }) => {
  return (
    <div className={classNames("flex items-center justify-center", className)}>
      <svg
        width="308"
        height="308"
        viewBox="0 0 308 308"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M285.046 183.374C297.028 165.134 304 143.309 304 119.853C304 55.8693 252.131 4 188.147 4C124.163 4 72.2934 55.8693 72.2934 119.853C72.2934 183.837 124.163 235.706 188.147 235.706C207.213 235.706 225.202 231.099 241.065 222.94M145.557 38.8981C158.278 32.192 172.768 28.3902 188.147 28.3902C238.66 28.3902 279.61 69.34 279.61 119.853C279.61 139.565 273.374 157.82 262.768 172.753M193.741 211.147C191.553 211.304 190.375 211.316 188.147 211.316C137.633 211.316 96.6837 170.367 96.6837 119.853C96.6837 104.475 100.484 89.9851 107.19 77.2668M119.528 213.87L38.8732 298.037C30.9226 305.988 17.9135 305.988 9.96294 298.037C2.01235 290.087 2.01235 277.077 9.96294 269.127L93.573 189.001M71.3168 213.749L94.2509 236.684M157.657 119.853H157.661M188.145 119.853H188.149M218.632 119.853H218.636M104.103 39.1464C100.836 33.5501 94.7661 29.7892 87.8173 29.7892C86.0777 29.7892 84.3942 30.0257 82.7954 30.4672C77.693 21.9721 68.3906 16.288 57.7601 16.288C41.6425 16.288 28.5773 29.3532 28.5773 45.4708H25.4303C17.4041 45.4708 10.8383 52.0366 10.8383 60.0622C10.8383 67.9933 17.3749 74.6536 25.334 74.6536H80.4149M123.468 55.1792H123.472M209.003 222.926H290.281C297.826 222.926 304 216.753 304 209.207C304 201.661 297.826 195.488 290.281 195.488C289.554 185.742 281.417 178.062 271.486 178.062C269.747 178.062 268.063 178.299 266.464 178.74C261.362 170.245 252.059 164.561 241.429 164.561C225.311 164.561 212.246 177.626 212.246 193.744H209.099C201.074 193.744 194.508 200.309 194.508 208.335C194.508 216.266 201.044 222.926 209.003 222.926Z"
          stroke="#FFCE16"
          strokeWidth="7"
          strokeMiterlimit="2.613"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
