import classNames from "classnames";
import { BasicProps } from "components/types/BasicProps";
import { FC } from "react";

type Props = BasicProps & {
  color?: "white" | "uyellow";
};

export const EtherscanIcon: FC<Props> = ({ className, color }) => {
  const hexColor = color === "uyellow" ? "#FFCE16" : "#FFFFFF";

  return (
    <div className={classNames("flex justify-center items-center", className)}>
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Vector">
          <path
            d="M5.18238 12.3852C5.1824 12.246 5.20992 12.1082 5.26334 11.9797C5.31676 11.8512 5.39504 11.7345 5.49369 11.6363C5.59236 11.5381 5.70941 11.4604 5.83818 11.4076C5.96695 11.3548 6.10488 11.328 6.24406 11.3286L8.0043 11.3343C8.28494 11.3343 8.5541 11.4458 8.75254 11.6443C8.951 11.8428 9.0625 12.1119 9.0625 12.3925V19.0483C9.26066 18.9895 9.51516 18.9268 9.79365 18.8612C9.98709 18.8158 10.1595 18.7063 10.2829 18.5505C10.4063 18.3948 10.4734 18.2019 10.4734 18.0032V9.74706C10.4734 9.46638 10.5848 9.19722 10.7833 8.99873C10.9817 8.80025 11.2509 8.68871 11.5316 8.68865H13.2953C13.5759 8.68871 13.8451 8.80025 14.0435 8.99873C14.242 9.19722 14.3535 9.46638 14.3535 9.74706V17.4096C14.3535 17.4096 14.7951 17.2309 15.2252 17.0493C15.385 16.9817 15.5213 16.8686 15.6173 16.7241C15.7132 16.5795 15.7644 16.4099 15.7646 16.2364V7.10156C15.7646 6.82095 15.876 6.55183 16.0744 6.35339C16.2728 6.15492 16.5419 6.04343 16.8225 6.04337H18.5863C18.8669 6.04337 19.1361 6.15486 19.3345 6.3533C19.533 6.55175 19.6445 6.82091 19.6445 7.10156V14.6239C21.1736 13.5157 22.7232 12.1829 23.9529 10.5803C24.1314 10.3476 24.2494 10.0745 24.2965 9.78513C24.3436 9.4958 24.3184 9.19929 24.2232 8.92205C23.6539 7.28431 22.7492 5.78341 21.5666 4.51543C20.384 3.24744 18.9498 2.24029 17.3556 1.55841C15.7615 0.876546 14.0426 0.534976 12.3088 0.555566C10.5751 0.576156 8.86477 0.958451 7.2873 1.67799C5.70979 2.39752 4.29988 3.43845 3.14777 4.73416C1.99566 6.02988 1.12673 7.55183 0.59658 9.20265C0.0664359 10.8534 -0.113248 12.5967 0.0690269 14.321C0.251301 16.0452 0.791523 17.7124 1.65511 19.2159C1.80556 19.4753 2.02688 19.6863 2.29309 19.8242C2.5593 19.9622 2.85934 20.0213 3.15799 19.9946C3.4916 19.9653 3.90697 19.9237 4.40082 19.8657C4.6158 19.8413 4.81428 19.7387 4.95844 19.5773C5.10262 19.416 5.1824 19.2073 5.18258 18.9909L5.18238 12.3852Z"
            fill={hexColor}
          />
          <path
            d="M5.14387 23.0604C7.00295 24.4129 9.19998 25.2247 11.4918 25.4061C13.7837 25.5872 16.0811 25.1311 18.1297 24.0877C20.1784 23.0442 21.8984 21.4545 23.0996 19.4942C24.3006 17.5339 24.9361 15.2795 24.9355 12.9804C24.9355 12.6936 24.9221 12.4097 24.9031 12.1275C20.35 18.9181 11.9433 22.0928 5.14426 23.0594"
            fill={hexColor}
          />
        </g>
      </svg>
    </div>
  );
};
