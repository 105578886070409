import { FC, FormEvent } from "react";
import { CloseButton } from "./shared/CloseButton";
import { withModal } from "./shared";
import classNames from "classnames";

type GenericModalProps = {
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
  headerTitle?: string;
  headerSubtitle?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  actions?: React.ReactNode;
  onSubmit?: (event: FormEvent) => void;
};

export const GenericModal: FC<GenericModalProps> = withModal(
  ({
    actions,
    children,
    headerTitle,
    toggleModal,
    className,
    headerSubtitle,
    onSubmit,
  }) => {
    return (
      <form
        className={classNames(
          `absolute top-[55%] -translate-y-1/2 modal w-[650px] pt-11 pb-12 px-12 flex flex-col items-center justify-center`,
          className
        )}
        onSubmit={onSubmit}
      >
        <CloseButton
          className="absolute right-[22px] top-[18px] w-6 h-6"
          onClick={() => toggleModal(false)}
        />
        {headerTitle && (
          <div className="w-full flex flex-col gap-1">
            <h2 className="mx-auto text-2xl font-bold">{headerTitle}</h2>
            {headerSubtitle && (
              <h3 className="mx-auto w-full flex flex-col items-center text-center">
                {headerSubtitle}
              </h3>
            )}
          </div>
        )}
        {children}
        {actions && <div className="flex justify-center gap-6">{actions}</div>}
      </form>
    );
  }
);
