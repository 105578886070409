import { FC } from "react";
import { BasicProps } from "../../types/BasicProps";
import classNames from "classnames";
import { IoMdClose } from "react-icons/io";

type Props = BasicProps & {
  onClick: () => void;
};

export const CloseButton: FC<Props> = ({ className, ...restProps }) => {
  return (
    <button
      className={classNames("text-white", className)}
      type="button"
      {...restProps}
    >
      <IoMdClose className="w-full h-full" />
    </button>
  );
};
