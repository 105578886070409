export const EthIconYellow = () => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.608398 8.74605L6.1084 0L11.6084 8.74605L6.1084 11.8657L0.608398 8.74605Z"
        fill="#FFCE16"
      />
      <path
        d="M6.1084 12.8484L11.4323 9.63035L6.1084 17L0.658941 9.63035L6.1084 12.8484Z"
        fill="#FFCE16"
      />
    </svg>
  );
};
